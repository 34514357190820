import React, {FC, memo} from 'react';
import {IMainProps} from '@common/types';
import RequestForm from '@components/RequestForm';
import ContactButtons from '@components/ContactButtons';
import ModalForm from '@components/modal/ModalForm';
import SuccessTooltip from '@components/SuccessTooltip';
import VideoModalPreview from '@components/component/VideoModalPreview';


const Main: FC<IMainProps> = ({ children }) => {
  return (
    <main>
      <ModalForm />
      <ContactButtons />
      {children}
      <VideoModalPreview />
      <RequestForm />
      <SuccessTooltip />
    </main>
  )
}


export default memo(Main)
