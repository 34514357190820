import React, {FC} from 'react';
import {IconProps} from '@common/types';


const Icon: FC<IconProps> = ({ width, height, name, className }) => {
  return (
    <svg className={className ?? ''} width={width} height={height} xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <use href={`/img/sprite/sprite_auto.svg#${name}`} />
    </svg>
  )
}

export default Icon
