import {useEffect} from 'react';
import {settings} from '@common/settings';
import {useRouter} from 'next/router';
import {useTypedSelector} from '@common/hooks/useTypedSelector';

export const useDraftMode = () => {
  const router = useRouter()
  const {draftMode} = useTypedSelector(state => state.app)
  
  useEffect(() => {
    if (settings.project.draftMode && !draftMode) {
      fetch(`${window.location.origin}/api/draft/enable`)
        .then(r => router.reload())
        .catch(e => console.log(e))
    }
  }, [])
}
