import {IncomingMessage, ServerResponse} from 'http';
import {ReactNode} from 'react';
import {ParsedUrlQuery} from 'querystring';
import {
  CardType, IAccordionItem,
  IAward, IBannerComponent, IBlog,
  ICard,
  IGridComponent,
  IListComponent,
  IListItem, IMediaComponent, INews, IPreview, IQuoteComponent,
  IStepComponent, ITableItem, ITag,
  TComponent, TStepChildPosition, TStepChildType
} from '@redux/types';
import {IProject} from '@redux/projects/types';
import {AppProps} from 'next/app';


export type TLang = 'ru' | 'en' | undefined

// enums

export enum EPage {
  HOME = 'home',
  ABOUT = 'about',
  AWARDS = 'awards',
  SERVICES = 'services',
  PROJECTS = 'projects',
  PROJECT_PAGE = 'projects-page',
  BLOG = 'blog',
  CONTACTS = 'contacts',
  VACANCY = 'vacancy',
  VACANCIES = 'vacancies',
  VACANCY_PAGE = 'vacancy-page',
  PERSONAL_INFORMATION = 'personal-information',
  REVIEWS = 'reviews',
  NEWS = 'news',
  SOLUTION = 'solution',
  CUSTOM_HEADER = 'custom-header',
  PARTNERS_PAGE = 'partners-page',
  EXPERTISE_PAGE = 'expertise-page'
}

export type TRequestFormType = 'request' | 'header' | 'download' | 'block' | 'requestPartners'

// interfaces

export interface IParam {
  [index: string]: string
}

export interface IStrapiSort {
  key: string
  direction: 'asc' | 'desc'
}

export interface IStrapiQuery {
  fields?: string[]
  populate?: string[] | string
  pagination?: {
    page: number,
    pageSize: number
  }
  sort?: IStrapiSort[]
  locale?: 'ru' | 'en'
}

export interface ICookieOptions {
  req?: IncomingMessage
  res?: ServerResponse
  httpOnly?: boolean
  maxAge?: number
}

export interface ISSRParams extends ParsedUrlQuery {
  slug?: string | string[]
}

export interface ILayoutProps {
  title?: string
  description?: string
  pipelineId: string | undefined
  classNames?: string[]
  children?: ReactNode
}

export interface IMainProps {
  children?: ReactNode
}

export interface INotFoundPageProps {
  locale: TLang
}

export interface IconProps {
  width: number
  height: number
  name: string
  className?: string
}


export interface ILanguage {
  ru: ITranslation
  en: ITranslation
}

export interface ITranslation {
  components: IComponentTranslation
  footer: IFooterTranslation
  forms: IFormTranslation
  main: IMainTranslation
  notFoundPage: INotFoundTranslation
  contactPage: IContactPageTranslation
  mainContact: IMainContactTranslation
  news: INewsPageTranslations
  vacancy: IVacancyTranslation
  home: IHomeTranslation
  projects: IProjectTranslation,
  partners: IParentsTranslation
}

export interface IParentsTranslation {
  title: string,
  hero: {
    years: string,
    teems: string,
    buttonTitle: string,
  },
  form: {
    stepTitle: string,
    contacts: {
      title: string,
      name: string,
      surname: string,
      nameCompany: string,
      position: string,
      email: string,
      telegram: string,
      site: string,
    },
    target: {
      title: string,
      field: string,
      variants: string[],
    },
    comment: {
      name: string,
      field:  string,
    },
    file: {
      title: string,
      field: string
    }
    button: string
  }
}

export interface IMainTranslation {
  requestTitle: string
  telegramLabel: string
}

export interface INotFoundTranslation {
  textGoal: string
  text404: string
  textMain: string
  textLink: string
}

export interface IVacancyTranslation {
  hiddenTitle: string
  contact: {
    title: string
    firstText: string
    secondText: string
  }
}

export interface IProjectTranslation {
  project: {
    similarProjects: {
      title: string
    }
    steps: {
      cooperation: {
        title: string
      }
    }
    visuallyHidden: string
  }
}

export interface IFooterTranslation {
  company: string
  present: string
  download: string
}

export interface IFormTranslation {
  blockTitle: string
  title: string
  name: string
  company: string
  task: string
  position: string
  email: string
  contact: string
  phone: string
  file: string
  button: string
  download: string
  policy: {
    text: string
    textLink: string
  }
  labels: {
    mb: string
  }
  errors: {
    bigFile: string
    message: string
  }
  successTooltip: {
    title: string
    description: string
  }
}
export interface IContactPageTranslation {
  title: string
  content: {
    fastTalk: string
    aboutProject: string
    write: string
    call?: string
    telegraph?: string
    or?: string
    onSite: string
    links: {
      telegraph: string
      request: string
    }

    goToGuests: string

    footer: {
      title: string
      text: string
      link: string
      promise: string
    }
  }
}

export interface IMainContactTranslation {
  title: string
  stepTitle: string
  email: string
  cities: {
    name: string
    phone: string
    address: string
  }[]
  description: {
    greetings: string
    formOpenText: string
    firstPart: string
    secondPart: string
    telegramText: string
  }
}

export interface INewsPageTranslations {
  card: {
    case: string
    soon: string
  }
  telegram: {
    title: string
    label: string
  }
  vk: {
    title: string
    label: string
  }
  youtube: {
    title: string
    label: string
  }
}

export interface IComponentTranslation {
  grid: {
    card: {
      linkAriaLabel: string
    }
  },
  award: {
    linkAriaLabel: string
  }
  detail: {
    reviews: string
    cooperation: string
  }
  slider: {
    buttonText: string
  }
  video: {
    visuallyHidden: string
  }
  homeMedia: {
    visuallyHidden: string
  }
  services: {
    visuallyHidden: string
  }
}

export interface IHomeTranslation {
  common: {
    titleMobile: string
  }
  hero: {
    projects: string
    years: string
    rating: string
    buttonTitle: string
  }
  services: {
    buttonTitle: string
  }
  news: {
    buttonTitle: string
  }
  project: {
    buttonTitle: string
  }
  awards: {
    buttonTitle: string
  }
  review: {
    buttonTitle: string
  }
}

export interface IStepProps {
  count: number
  step: IStepComponent
  stepChildren?: TComponent[]
  classNames?: string[]
  children?: ReactNode
  childrenPosition?: TStepChildPosition
  link?: {
    title: string
    titleMobile: string
    url: string
  }
  withoutMainClassname?: boolean
  id?: string
}

export interface IGridProps extends Omit<IGridComponent, '__component' | 'cards'> {
  items: ICard[],
  height?: string | null
  type?: TStepChildType | null
  position?: TStepChildPosition | null
  parent?: IStepComponent | null
}

export interface ILocationMapProps {
  lat: number
  lng: number
}

export interface IListProps extends Omit<IListComponent, '__component' | 'lists'> {
  items: IListItem[]
  height?: string | null
  position: TStepChildPosition
  type: TStepChildType | null
}

export interface IAccordionProps extends Omit<IListComponent, '__component' | 'lists'> {
  items: IAccordionItem[]
}

export interface ICaseListItem extends Omit<IListItem, '__component' | 'tags' | 'text'> {
  note?: string
  slug: string
  date?: string
  text?: any[]
  description?: string
  tags: ITag[] | string[]
}

export interface ICaseListProps extends Omit<IListComponent, '__component' | 'lists'> {
  items: ICaseListItem[]
  height?: string | null
  isList?: boolean
  position?: TStepChildPosition
  type?: TStepChildType | null
}

export interface ITableProps extends Omit<IListComponent, '__component' | 'lists'> {
  title?: string
  items: ITableItem[]
  position?: TStepChildPosition
}

export interface IStepChildrenProps {
  components: TComponent[]
  parentStep?: IStepComponent
}

export interface IAwardsGridProps {
  items: IAward[]
}

export interface IAwardCardProps {
  item: IAward
}

export interface IProjectGridProps {
  projects: IProject[]
  isHomePageGrid?: boolean
}

export interface IProjectListProps {
  projects: ICaseListItem[]
  isHomePageGrid?: boolean
}

export interface IPictureProps {
  media: IMediaComponent | null
  isProjectPreview?: boolean
  wrapperClassName?: string
  wrapperCaptionClassName?: string
  withoutWrapper?: boolean
}

export interface IQuoteProps {
  quote: IQuoteComponent
}

export interface IBannerProps {
  banner: IBannerComponent
}

export interface IMainContactsProps {
  withPadding?: boolean
  count?: number
}

export interface IReviewsItem {
  slug?: string
  title: string
  description: string
  color?: string
  cardType?: CardType
  customer?: string
  preview?: IPreview | null,
  onlyPreview?: boolean
}

export interface IReviewsProps {
  type: 'reviews' | 'services'
  reviews: IReviewsItem[]
}

export interface INewsGridProps {
  news: INews[]
}

export interface IBlogPostProps {
  blogs: IBlog[]
  selectedTagSlug: string | null
}

export interface INewsCardProps {
  item: INews
  classNames: string[]
  withLink?: string | null
}

export interface IModalFormContentProps {
  type: 'header' | 'request' | 'download'
  closeForm: () => void
}

export interface IStaticPathParam {
  params: {slug: string}
  locale: string
}

export interface AppOriginProps extends AppProps {
  canonicalHref: string
}
