import {FC} from 'react';

const IconLoader: FC<{show?: boolean}> = ({show}) => {
  const classNames = 'form-request__button-icon form-request__button-loader form-block__button-loader form-modal__button-loader'
  return (
    <svg
      className={!show ? classNames : ''}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{shapeRendering: 'auto'}}
      viewBox="0 0 100 100"
      height="25"
      width="25"
      preserveAspectRatio="xMidYMid"
    >
      <path d="M10 50A40 40 0 0 0 90 50A40 43.1 0 0 1 10 50" fill="#ffffff" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite"
                          keyTimes="0;1" values="0 50 51.55;360 50 51.55"></animateTransform>
      </path>
    </svg>
  )
}

export {
  IconLoader,
}
