import React, {memo} from 'react';
import {socials} from '@common/layout/layout.constants';
import moment from 'moment';
import Icon from '@components/Icon';
import ym from 'react-yandex-metrika';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import Link from 'next/link';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useActions} from '@common/hooks/useActions';


export const Footer = () => {
  const actions = useActions()
  const { contacts } = useTypedSelector(state => state.contacts)
  const t  = useTranslation('footer') as ITranslation['footer']

  return (
    <footer className="footer">
      <div className="container">
        <ul className="social">
          {socials.map((s, index) => (
            <li key={`contact_${s.key}_${index}`} className="social__item">
              <Link
                href={contacts?.socialUrls[s.key] ?? s.href}
                aria-label={s.ariaLabel}
                className={`social__link ${s.class}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => ym('reachGoal', 'social', 'network_name')}
              >
                <Icon width={48} height={48} name={s.name} />
              </Link>
            </li>
          ))}
        </ul>

        <p className="footer__copyright">
          &#169; 2009–{moment().format('YYYY')}, {t.company}
        </p>

        <a
          className="footer__link"
          onClick={() => actions.setApp({ modalForm: { form: 'download', open: true }})}
        >
          {t.present}
        </a>
      </div>
    </footer>
  )
}

export default memo(Footer)
