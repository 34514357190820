import React, {useEffect, useState} from 'react';
import Icon from '@components/Icon';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useActions} from '@common/hooks/useActions';
import {Form, Input, Upload, UploadFile} from 'antd';
import Link from 'next/link';
import {IconLoader} from '@components/CustomIcons';
import {UploadChangeParam} from 'antd/es/upload/interface';
import {FORM_REQUIRED_RULE, REQUEST_FORM_SOURCE, TO_MB} from '@common/constants';
import {NamePath} from 'rc-field-form/es/interface';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import {useRouter} from 'next/router';
import {useRequestForm} from '@common/hooks/useRequest';
import Image from 'next/image';
import Text from '@components/Text';


const RequestForm = () => {
  const actions = useActions()
  const {locale} = useRouter()
  const {form, checkFieldLabel, setFocusedInput, sendForm} = useRequestForm('request')
  const {requestForm} = useTypedSelector(state => state.app)
  const t = useTranslation('forms') as ITranslation['forms']
  const [isSourceOpen, setIsSourceOpen] = useState<boolean>(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isSourceError, setIsSourceError] = useState<boolean>(false)

  const closeRequestForm = () => {
    actions.setApp({requestForm: {...requestForm, open: false}})
    setFileList([])
    form.setFieldsValue({file: undefined})
  }

  const addFile = (info: UploadChangeParam) => {
    let file = info.fileList[0]
    if (file?.size && (file.size / TO_MB > 10)) {
      file = {...file, status: 'error'}
    }
    setFileList([file])
    form.setFieldsValue({file: file.originFileObj})
  }

  const removeFile = () => {
    setFileList([])
    form.setFieldsValue({file: undefined})
  }

  const openSource = () => {
    setIsSourceOpen(prevState => !prevState)
    setIsSourceError(false)
  }

  return (
    <section className={`form-request ${requestForm?.open ? 'is-active' : ''}`}>
      <div className="form-request__overlay" onClick={closeRequestForm}/>

      <button className="form-request__close" onClick={closeRequestForm}>
        <Icon width={30} height={30} name="icon-close" className="form-request__close-icon"/>
      </button>

      <div className="form-request__content">
        <h2 className="form-request__title">
          <Text text={t.title}/>
        </h2>
        <div className="form-request__wrapper">
          <Form
            onFocus={(e) => setFocusedInput(e.target.id ?? '')}
            onBlur={() => setFocusedInput('')}
            form={form}
            className="form-request__form"
            onFinish={sendForm}
          >
            <Form.Item noStyle={true} name="nameContact" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-request__input-box">
                <label>
                  <Input id="nameContact"/>
                  <span className={`custom-input__label ${checkFieldLabel('nameContact') ? 'is-focus' : ''}`}>
                    {t.name}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="nameCompany" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-request__input-box">
                <label>
                  <Input id="nameCompany"/>
                  <span className={`custom-input__label ${checkFieldLabel('nameCompany') ? 'is-focus' : ''}`}>
                    {t.company}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="responsible" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-request__input-box">
                <label>
                  <Input id="responsible"/>
                  <span className={`custom-input__label ${checkFieldLabel('responsible') ? 'is-focus' : ''}`}>
                    {t.position}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="tasks" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-textarea form-request__input-box auto-resize-textarea">
                <label>
                  <Input.TextArea
                    id="tasks"
                    autoSize={true}
                    rows={1}
                  />
                  <span className={`custom-textarea__label ${checkFieldLabel('tasks') ? 'is-focus' : ''}`}>
                    {t.task}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="file">
              <div
                className={`custom-input form-request__input-box form-request__file file-upload ${fileList?.[0]?.status === 'error' ? 'is-invalid' : ''}`}>
                {!fileList.length ? (
                  <Upload
                    style={{width: '100%'}}
                    maxCount={1}
                    fileList={fileList}
                    onChange={addFile}
                    showUploadList={false}
                  >
                    <label className="file-upload__label">
                      <span className="file-upload__button">{t.file}</span>
                    </label>
                  </Upload>
                ) : (
                  <p className={`file-upload__result ${fileList?.[0]?.status === 'error' ? 'is-error' : 'is-active'}`}>
                    <span className="file-upload__file-name">
                      {fileList[0].name}
                      &thinsp;
                      ({fileList[0]?.size ? (fileList[0]?.size / TO_MB).toFixed(1) : 0}{t.labels.mb})
                      &thinsp;
                      {fileList?.[0]?.status === 'error' && t.errors.bigFile}
                    </span>
                    <button className="file-upload__delete" type="button" onClick={removeFile}>
                      <Icon width={12} height={12} name="icon-close" className="file-upload__delete-icon"/>
                    </button>
                  </p>
                )}
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="phone" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-request__input-box">
                <label>
                  <Input id="phone"/>
                  <span className={`custom-input__label ${checkFieldLabel('phone') ? 'is-focus' : ''}`}>
                    {t.contact}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="source" rules={[FORM_REQUIRED_RULE]}>
              <div
                className={[
                  `custom-select is-initialized`,
                  form.getFieldValue('source' as NamePath) ? 'not-empty is-valid' : '',
                  isSourceOpen ? 'is-open' : '',
                  isSourceError ? 'is-invalid' : '',
                ].join(' ')}>
                <span className="custom-select__button" onClick={openSource}>
                  <span className="custom-select__text">
                    {REQUEST_FORM_SOURCE.find(r => r.value === form?.getFieldValue('source' as NamePath))?.name[locale === 'en' ? 'en' : 'ru']}
                  </span>
                  
                  <span className="custom-select__icon">
                    <Image src="/img/sprite/icon-select-arrow.svg" alt="Выбрать" width={12} height={8} />
                  </span>
                </span>
                <span className="custom-select__label" aria-label="Выберите одну из опций">
                  Откуда вы о нас узнали
                </span>
                <ul className="custom-select__list" role="listbox">
                  {REQUEST_FORM_SOURCE.map(rfs => (
                    <li
                      key={`source_item_${rfs.value}`}
                      className="custom-select__item"
                      tabIndex={0}
                      data-select-value={rfs.value}
                      aria-selected="false"
                      role="option"
                      onClick={() => {
                        form.setFieldsValue({source: rfs.value})
                        setIsSourceOpen(false)
                      }}
                    >
                      {rfs.name[locale === 'en' ? 'en' : 'ru']}
                    </li>
                  ))}
                </ul>
              </div>
            </Form.Item>
  
            {requestForm?.status === 'error' && (
              <p className="error-message">{t.errors.message}</p>
            )}

            <button
              className={`form-request__button is-${requestForm?.status}`}
              type="submit"
              onClick={() => setIsSourceError(!form.getFieldValue('source' as NamePath))}
            >
              <span className="form-request__button-text">{t.button}</span>
              <IconLoader/>
              <Icon
                width={19}
                height={13}
                name="icon-success"
                className="form-request__button-icon form-request__button-success"
              />
            </button>

            <p className="form-request__note">
              {t.policy.text}&nbsp;
              <Link className="form-request__note-link" href="/personal-information" onClick={closeRequestForm}>
                {t.policy.textLink}
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </section>
  )
}

export default RequestForm
