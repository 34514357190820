import React, {memo} from 'react';
import DOMPurify from 'isomorphic-dompurify';


const Text: React.FC<{text?: string | null}> = ({text = ''}) => {
  if (!text) {
    return  ''
  }
  const clean = DOMPurify.sanitize(text);
  return (
    <span style={{whiteSpace: "break-spaces"}} dangerouslySetInnerHTML={{ __html: clean }}/>
  )
}

export default memo(Text)
